import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 style={{
      display: 'flex',
      alignItems: 'center'
    }}>
  <img src="/android-chrome-192x192.png" alt="Goldie logo" style={{
        height: "1em"
      }} />
  {' '} Goldie
    </h1>
    <p>{`A modern Simple IRA solution for small businesses.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      