import React from 'react';
import { Parallax } from 'react-spring/renderprops-addons.cjs';
import Layout from '@lekoarts/gatsby-theme-cara/src/components/layout';
import Hero from '@lekoarts/gatsby-theme-cara/src/components/hero';
// import Projects from '@lekoarts/gatsby-theme-cara/src/components/projects';
import Articles from '@lekoarts/gatsby-theme-cara/src/components/about';
import Newsletter from '@lekoarts/gatsby-theme-cara/src/components/contact';

const Cara = () => (
  <Layout>
    <Parallax pages={3}>
      <Hero offset={0} />
      <Articles offset={1} />
      {/* <Projects offset={2} /> */}
      <Newsletter offset={2} />
    </Parallax>
  </Layout>
);

export default Cara;
