import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { NewsletterSignup } from '../../../components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Join the wait list`}</h1>
    <p>{`Interested in using goldie for Simple IRAs for your employees? Join the wait list and you'll get updates on the product, as well as an exclusive invitation when accounts are available.`}</p>
    <NewsletterSignup mdxType="NewsletterSignup" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      